import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

const OUTER_CONTAINER_CLASS = "flex self-center place-items-center justify-center min-h-screen px-4 sm:px-6 md:px-12";
const INNER_CONTAINER_CLASS = "text-center align-middle mx-auto";
const HEADER_CLASS = "text-4xl sm:text-5xl md:text-6xl lg:text-[110px] font-bold text-white";
const SUBHEADER_CLASS = "text-lg sm:text-xl md:text-2xl lg:text-3xl text-white mt-3";
const LINK_CLASS = "font-bold text-white mt-5 text-sm sm:text-base";

// Extract function for header section
function HeaderSection() {
    return (
        <h1 className={HEADER_CLASS}>Hi, I'm <span className={'text-red-600'}>Elango</span></h1>
    );
}

// Extract function for subheader section
function SubheaderSection() {
    return (
        <p className={SUBHEADER_CLASS}>
            I am a <span className="font-bold text-red-600">Full-Stack Web Developer</span> with over four years of experience in creating <span className="font-bold">dynamic web applications</span> using <span className="font-bold">PHP Laravel</span>, <span className="font-bold">Filament</span>, <span className="font-bold">Alpine.js</span>, and <span className="font-bold">React</span>.
        </p>
    );
}

// Extract function for link section
function LinkSection() {
    return (
        <p className={LINK_CLASS}>
            <Link to="/about">Know More</Link>
        </p>
    );
}

function HomePageContent() {

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setScrollPosition(scrollY);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div
                className={`sticky-home-content`}
                style={{
                    transform: `translateY(${scrollPosition * -0.3}px)`,
                    transition: "transform 0.2s ease-out",
                }}
            >
                <div className={OUTER_CONTAINER_CLASS}>
                    <div className={INNER_CONTAINER_CLASS}>
                        <HeaderSection/>
                        <SubheaderSection/>
                        <LinkSection/>
                    </div>
                </div>
            </div>
            <div className="project-section">
                {/*<Projects/>*/}
            </div>
        </>
    );
}

export default HomePageContent;