import {Link, useLocation} from "react-router-dom";

const navLinkClass = "text-[12px] leading-[14px]";

const AnchorLink = ({ to, children }) => {
    const location = useLocation();
    const isActive = to === "/"
        ? location.pathname === to // Only match "/" for root path
        : location.pathname.startsWith(to);


    return (
        <li className={`${isActive ? 'menu-item active' : 'menu-item'}`}>
            <Link
                to={to}
                className={`${navLinkClass} ${isActive ? 'text-red-600 font-bold' : 'sm:text-white text-black'} hover:text-red-600 hover:font-bold`}
            >
                {isActive ? `< ${children} />` : children}
            </Link>
        </li>
    );
};

export default AnchorLink;