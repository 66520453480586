import { useEffect, useState, useCallback } from 'react';
import { Link } from "react-router-dom";
import AnchorLink from "../components/AnchorLink";
import ElangoLogo from "../components/Logo";
import { HiOutlineMenu, HiX } from "react-icons/hi";

const NavigationLinks = ({ className, onClick }) => {
    return (
        <ul className={`flex flex-col md:flex-row gap-7 ${className}`} onClick={onClick}>
            <AnchorLink to="/">Home</AnchorLink>
            <AnchorLink to="/about">About</AnchorLink>
            <AnchorLink to="/projects">Work</AnchorLink>
            <AnchorLink to="/contact">Contact</AnchorLink>
            <AnchorLink to="/resume">Resume</AnchorLink>
        </ul>
    );
};

function Header() {
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu toggle

    const handleScroll = useCallback(() => {
        if (window.scrollY > lastScrollY && window.scrollY > 100) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
        setLastScrollY(window.scrollY);
    }, [lastScrollY]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header
            className={`header px-6 md:px-12 items-center justify-between uppercase flex transition-all duration-1000 fixed w-full h-16 md:h-32 z-50 bg-white sm:bg-transparent ${
                isVisible ? 'top-0' : '-top-full'
            }`}
            style={{ fontFamily: "Helvetica" }}
        >
            <Link
                to="/"
                className="text-xl md:text-2xl relative z-10"
            >
                <ElangoLogo />
            </Link>

            {/* Mobile Menu Toggle (Hamburger Icon) */}
            <button
                className="md:hidden text-2xl"
                onClick={toggleMenu}
                aria-label="Toggle navigation"
            >
                {isMenuOpen ? <HiX /> : <HiOutlineMenu />}
            </button>

            {/* Navigation for Desktop and Mobile */}
            <nav
                className={`main-d-nav fixed md:static top-16 right-0 md:top-0 h-screen md:h-auto w-full md:w-auto bg-white md:bg-transparent transition-transform transform ${
                    isMenuOpen ? "translate-x-0" : "translate-x-full"
                } md:translate-x-0`}
            >
                <NavigationLinks
                    className="md:flex-row md:gap-7 flex-col gap-5 text-xl items-center text-black justify-center h-full"
                    onClick={() => setIsMenuOpen(false)}
                />
            </nav>
        </header>
    );
}

export default Header;