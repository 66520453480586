import React from "react";
import {Link, useParams} from "react-router-dom";
import suiteNYHome from "../assets/projects/codezone/suite-ny/home.jpeg";
import suiteNYProducts from "../assets/projects/codezone/suite-ny/products.jpeg";
import suiteNYBrands from "../assets/projects/codezone/suite-ny/brands.jpeg";
import homepageImg from "../assets/projects/alliance/images/annual-report/an-report-home.png";
import arHome from "../assets/projects/alliance/images/annual-report/an-report-home.png";
import arAdmin from "../assets/projects/alliance/images/annual-report/an-report-admin.png";
import arReportList from "../assets/projects/alliance/images/annual-report/an-report-list.png";
import atmnHome from "../assets/projects/alliance/images/atmn/atmn-home.png";
import atmnLogin from "../assets/projects/alliance/images/atmn/atmn-login.png";
import atmnAdmin from "../assets/projects/alliance/images/atmn/atmn-admin.png";
import allianceHome from "../assets/projects/alliance/images/cmalliance/alliance-home.png";
import allianceLogin from "../assets/projects/alliance/images/cmalliance/alliance-login.png";
import allianceServe from "../assets/projects/alliance/images/cmalliance/alliance-serve.png";

const ProjectDetail = () => {
    const { id } = useParams(); // Get project ID from URL\\

    debugger;

    const projects = [
        {
            description:
                "Built two e-commerce platforms for interior designers using Laravel and Filament. Developed a unified admin dashboard for product management and enhanced user workflows with project list-to-estimate functionality.",
            id: 1,
            images: [suiteNYHome, suiteNYProducts, suiteNYBrands],
            location: "CodeZone, United States",
            title: "SuiteNY & CSuiteNY",
            type: "E-Commerce Platform",
        },
        {
            id : 2,
            title: "LifeBook - Fostering Connections",
            type: "Web Application",
            location: "CodeZone, United States",
            description:
                "Designed and implemented secure RESTful APIs for managing health records. Developed key features for user account operations and confidential record-sharing, enhancing administrative productivity with Filament.",
            images: [homepageImg, homepageImg, homepageImg],
        },
        {
            id : 3,
            title: "East-West Payment Portal",
            type: "Financial Web Platform",
            location: "CodeZone, United States",
            description:
                "Developed a portal to streamline offline donation processing, including batch creation, automated data posting to Classy and Salesforce, and PDF receipt generation for tax purposes.",
            images: [homepageImg, homepageImg, homepageImg],
        },
        {
            id : 4,
            title: "Annual Report - Alliance Churches",
            type: "Report Management System CMAlliance",
            location: "C’S|3 Consultancy Group, United States",
            description:
                "Facilitated efficient and accurate annual report submissions for affiliated churches, improving system robustness and user experience.",
            images: [arHome, arAdmin, arReportList],
        },
        {
            id : 5,
            title: "ATMN - Alliance Transitional Ministry Network",
            type: "Church Support Platform CMAlliance",
            location: "C’S|3 Consultancy Group, United States",
            description:
                "Developed a web application for supporting churches during leadership transitions, ensuring smooth onboarding processes and resource management.",
            images: [atmnHome, atmnLogin, atmnAdmin],
        },
        {
            id : 6,
            title: "Alliance Breakthrough",
            type: "CMS Platform CMAlliance",
            location: "C’S|3 Consultancy Group, United States",
            description:
                "CMAlliance is an integrated online portal for training and ministry development. From preparing to maintain all the workers, pastors, donors, leaders, international workers, and national office employees in a local church. It serves the Alliance family by bringing cost-effective, just-in-time training to Alliance workers, churches, and leaders.",
            images: [allianceHome, allianceLogin, allianceServe],
        },
    ];

    // Find the project with the matching ID
    const project = projects.find((proj) => proj.id === parseInt(id));

    if (!project) {
        return <p>Project not found</p>;
    }

    return (
        <div className="my-48">
            <div className="mt-16">
                <main className="py-6 px-4 sm:p-6 md:py-10 md:px-8">
                    <div className="max-w-4xl mx-auto grid lg:max-w-5xl lg:gap-x-20">
                        {/* Breadcrumb */}
                        <nav className="breadcrumb mb-10">
                            <ul className="flex space-x-2 text-sm text-gray-500">
                                <li >
                                    <Link to="/projects" className="hover:underline text-red-600">
                                        Work
                                    </Link>
                                    <span> / </span>
                                </li>
                                <li>{project.title}</li>
                            </ul>
                        </nav>
                    </div>
                    <div className="max-w-4xl mx-auto grid lg:max-w-5xl lg:gap-x-20">
                        <p className="text-sm leading-4 font-medium text-red-600 sm:text-red-600 dark:sm:text-red-600">
                            {project.type}
                        </p>
                        <h1 className="text-4xl font-bold text-white mt-4"><span>{project.title}</span></h1>
                        <dd className="flex items-center text-gray-50 mt-3">
                            <svg
                                width={24}
                                height={24}
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="mr-1 text-slate-400 dark:text-slate-500"
                                aria-hidden="true"
                            >
                                <path
                                    d="M18 11.034C18 14.897 12 19 12 19s-6-4.103-6-7.966C6 7.655 8.819 5 12 5s6 2.655 6 6.034Z"/>
                                <path d="M14 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
                            </svg>
                            {project.location}
                        </dd>
                        <p className="my-4 text-sm leading-6 col-start-1 sm:col-span-2 lg:mt-6 lg:row-start-4 lg:col-span-1 text-slate-300">{project.description}</p>
                        <div className="grid grid-cols-1 gap-6">
                            {project.images.map((image, idx) => (
                                <img
                                    key={idx}
                                    src={image}
                                    alt={project.title}
                                    onError={(e) => e.target.src = homepageImg}
                                />
                            ))}
                        </div>

                    </div>
                </main>
            </div>
        </div>
    );
};

export default ProjectDetail;