    import React from 'react';

    function Resume() {
        return (
            <div className="max-w-7xl mx-auto my-48">
                <div className="mt-16">
                    <main className="py-6 px-4 sm:p-6 md:py-10 md:px-8">
                        <div className="max-w-4xl mx-auto grid lg:max-w-5xl lg:gap-x-20">
                            <h1 className="text-4xl font-bold text-white">
                                My <span className="text-red-600">Resume</span>
                            </h1>

                            {/* Skills Section */}
                            <section className="mt-8">
                                <h2 className="text-2xl font-bold text-white">Skills</h2>
                                <ul className="mt-4 text-white space-y-2">
                                    <li>- JavaScript / React / Node.js</li>
                                    <li>- TailwindCSS / CSS3 / HTML5</li>
                                    <li>- PHP / Laravel / Livewire / Filament</li>
                                    <li>- Docker / DDEV</li>
                                    <li>- MySQL </li>
                                    <li>- Agile / Scrum</li>
                                    <li>- Git / GitHub / GitLab / CI-CD</li>
                                    <li>- Linux / Windows / Mac</li>
                                    <li>- Jetbrains / VSCode / Terminal Workflow</li>
                                </ul>
                            </section>

                            {/* Download Resume Button */}
                            <section className="mt-8">
                                <button className="bg-red-600 text-white px-6 py-2 rounded hover:bg-red-700">
                                    Download Resume
                                </button>
                            </section>
                        </div>
                    </main>
                </div>
            </div>
        );
    }

    export default Resume;