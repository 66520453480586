import React from "react";
import allianceHome from "../assets/projects/alliance/images/cmalliance/alliance-home.png";
import allianceLogin from "../assets/projects/alliance/images/cmalliance/alliance-login.png";
import allianceServe from "../assets/projects/alliance/images/cmalliance/alliance-serve.png";
import atmnHome from "../assets/projects/alliance/images/atmn/atmn-home.png";
import atmnLogin from "../assets/projects/alliance/images/atmn/atmn-login.png";
import atmnAdmin from "../assets/projects/alliance/images/atmn/atmn-admin.png";
import arHome from "../assets/projects/alliance/images/annual-report/an-report-home.png";
import arAdmin from "../assets/projects/alliance/images/annual-report/an-report-admin.png";
import arReportList from "../assets/projects/alliance/images/annual-report/an-report-list.png";
import ewDashboard from "../assets/projects/codezone/ew-payment/dashboard.png";
import ewLogin from "../assets/projects/codezone/ew-payment/login.png";
import ewPage from "../assets/projects/codezone/ew-payment/page.png";
import lifeDashboard from "../assets/projects/codezone/lifebook/dashboard.png";
import lifeLogin from "../assets/projects/codezone/lifebook/login.png";
import lifePage from "../assets/projects/codezone/lifebook/childpage.png";
import suiteNYHome from "../assets/projects/codezone/suite-ny/home.jpeg";
import suiteNYProducts from "../assets/projects/codezone/suite-ny/products.jpeg";
import suiteNYBrands from "../assets/projects/codezone/suite-ny/brands.jpeg";
import {Link} from "react-router-dom";

function Work() {

    const projects = [
        {
            id : 1,
            title: "SuiteNY & CSuiteNY",
            type: "E-Commerce Platform",
            location: "CodeZone, United States",
            description:
                "Built two e-commerce platforms for interior designers using Laravel and Filament. Developed a unified admin dashboard for product management and enhanced user workflows with project list-to-estimate functionality.",
            images: [suiteNYHome, suiteNYProducts, suiteNYBrands],
        },
        {
            id : 2,
            title: "LifeBook - Fostering Connections",
            type: "Web Application",
            location: "CodeZone, United States",
            description:
                "Designed and implemented secure RESTful APIs for managing health records. Developed key features for user account operations and confidential record-sharing, enhancing administrative productivity with Filament.",
            images: [lifeDashboard, lifeLogin, lifePage],
        },
        {
            id : 3,
            title: "East-West Payment Portal",
            type: "Financial Web Platform",
            location: "CodeZone, United States",
            description:
                "Developed a portal to streamline offline donation processing, including batch creation, automated data posting to Classy and Salesforce, and PDF receipt generation for tax purposes.",
            images: [ewDashboard, ewLogin, ewPage],
        },
        {
            id : 4,
            title: "Annual Report - Alliance Churches",
            type: "Report Management System CMAlliance",
            location: "C’S|3 Consultancy Group, United States",
            description:
                "Facilitated efficient and accurate annual report submissions for affiliated churches, improving system robustness and user experience.",
            images: [arHome, arAdmin, arReportList],
        },
        {
            id : 5,
            title: "ATMN - Alliance Transitional Ministry Network",
            type: "Church Support Platform CMAlliance",
            location: "C’S|3 Consultancy Group, United States",
            description:
                "Developed a web application for supporting churches during leadership transitions, ensuring smooth onboarding processes and resource management.",
            images: [atmnHome, atmnLogin, atmnAdmin],
        },
        {
            id : 6,
            title: "Alliance Breakthrough",
            type: "CMS Platform CMAlliance",
            location: "C’S|3 Consultancy Group, United States",
            description:
                "CMAlliance is an integrated online portal for training and ministry development. From preparing to maintain all the workers, pastors, donors, leaders, international workers, and national office employees in a local church. It serves the Alliance family by bringing cost-effective, just-in-time training to Alliance workers, churches, and leaders.",
            images: [allianceHome, allianceLogin, allianceServe],
        },
    ];

    return (
        <div className="my-48">
            <div className="mt-16">
                <main className="py-6 px-4 sm:p-6 md:py-10 md:px-8">
                    <div className="max-w-4xl mx-auto grid lg:max-w-5xl lg:gap-x-20">
                        <h1 className="text-4xl font-bold text-white">My <span className={'text-red-600'}>Work</span></h1>
                        <p className="text-sm text-gray-500 mt-2">
                            A collection of projects I've worked on.
                        </p>
                    </div>
                </main>
            </div>
            {projects.map((project, index) => (
                <div className="flex items-center justify-center mt-16" key={index}>
                    <main className="py-6 px-4 sm:p-6 md:py-10 md:px-8">
                        <div
                            className="max-w-4xl mx-auto grid grid-cols-1 lg:max-w-5xl lg:gap-x-20 lg:grid-cols-2">
                            {/* Project Title and Type */}
                            <div
                                className="relative p-3 col-start-1 row-start-1 flex flex-col-reverse rounded-lg bg-gradient-to-t from-black/75 via-black/0 sm:bg-none sm:row-start-2 sm:p-0 lg:row-start-1">
                                <h1 className="mt-1 text-lg font-semibold text-white sm:text-slate-300 md:text-2xl dark:sm:text-white">
                                    <Link to={`/projects/${project.id}`} className="text-inherit hover:underline focus:underline">
                                        {project.title}
                                    </Link>
                                </h1>
                                <p className="text-sm leading-4 font-medium text-red-600 sm:text-red-600 dark:sm:text-red-600">
                                    {project.type}
                                </p>
                            </div>
                            {/* Images */}
                            <div
                                className="grid gap-4 col-start-1 col-end-3 row-start-1 sm:mb-6 sm:grid-cols-4 lg:gap-6 lg:col-start-2 lg:row-end-6 lg:row-span-6 lg:mb-0">
                                {project.images.slice(0, 3).map((image, imgIndex) => (
                                    <img
                                        key={imgIndex}
                                        src={image}
                                        alt={`${project.title} - ${imgIndex === 0 ? "main view" : `view ${imgIndex + 1}`}`}
                                        className={
                                            imgIndex === 0
                                                ? "w-full h-60 object-cover rounded-lg sm:h-52 sm:col-span-2 lg:col-span-full"
                                                : "hidden w-full h-52 object-cover rounded-lg sm:block sm:col-span-2 md:col-span-1 lg:row-start-2 lg:col-span-2 lg:h-32"
                                        }
                                        loading="lazy"
                                    />
                                ))}
                            </div>
                            {/* Location */}
                            <dl className="mt-4 text-xs font-medium flex items-center row-start-2 sm:mt-1 sm:row-start-3 md:mt-2.5 lg:row-start-2">
                                <dt className="sr-only">Location</dt>
                                <dd className="flex items-center text-gray-50">
                                    <svg
                                        width={24}
                                        height={24}
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="mr-1 text-slate-400 dark:text-slate-500"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M18 11.034C18 14.897 12 19 12 19s-6-4.103-6-7.966C6 7.655 8.819 5 12 5s6 2.655 6 6.034Z"/>
                                        <path d="M14 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
                                    </svg>
                                    {project.location}
                                </dd>

                            </dl>
                            {/* Description */}
                            <p className="mt-4 text-sm leading-6 col-start-1 sm:col-span-2 lg:mt-6 lg:row-start-4 lg:col-span-1 text-slate-300">
                                {project.description}
                            </p>
                            <br/>
                        </div>
                        {index < projects.length - 1 && (
                            <hr className="mt-[8.5rem] border-t border-gray-300 dark:border-gray-700"/>
                        )}
                    </main>
                </div>
            ))}
        </div>
    );
}

export default Work;