import React from 'react';

function About() {
    return (
        <div className="my-48">
            <div className="mt-16">
                <main className="py-6 px-4 sm:p-6 md:py-10 md:px-8">
                    <div className="max-w-4xl mx-auto grid lg:max-w-5xl lg:gap-x-20">
                        {/* Header */}
                        <h1 className="text-4xl font-bold text-white">About <span className="text-red-600">Me</span></h1>

                        {/* Profile Section */}
                        <div className="mt-20 text-white">
                            <div className="flex flex-wrap lg:flex-nowrap lg:gap-6">
                                {/* Profile Content */}
                                <div>
                                    <h2 className="text-2xl font-semibold">Hi, I'm Elango</h2>
                                    <p className="mt-4">
                                        Welcome! I am a passionate and dedicated
                                        <span className="font-bold text-red-600"> Full-Stack Web Developer</span> with over
                                        <span className="font-bold"> four years</span> of experience in building robust,
                                        dynamic, and scalable web applications. I specialize in
                                        <span className="font-bold"> PHP Laravel, Filament, Alpine.js, React</span>, and
                                        <span className="font-bold"> TailwindCSS</span>.
                                    </p>
                                    <p className="mt-4">
                                        Over the years, I have developed strong problem-solving skills, a deep understanding of modern development workflows, and the ability to collaborate in agile teams to deliver high-quality solutions. I am always eager to learn and keep up with the latest tech trends to bring efficient, user-friendly web applications to life.
                                    </p>

                                </div>
                                {/* Profile Image */}
                                <img
                                    src={`${process.env.PUBLIC_URL}/image/profile/elango.jpeg`}
                                    alt="Profile"
                                    className="mb-4 lg:mb-0 lg:float-right lg:self-start lg:ml-6 w-96 h-auto shadow-lg rounded-lg pt-6 sm:pt-0"
                                />
                            </div>
                        </div>

                        {/* Skills Section */}
                        <div className="mt-8">
                        <h3 className="text-xl font-semibold text-red-600">Technical Skills</h3>
                            <ul className="mt-4 space-y-2 text-white">
                                <li><strong>Languages:</strong> PHP, JavaScript</li>
                                <li><strong>Frameworks:</strong> Laravel, React, Filament</li>
                                <li><strong>Frontend:</strong> TailwindCSS, Alpine.js</li>
                                <li><strong>DevOps Tools:</strong> Docker, Git, Bitrix24</li>
                                <li><strong>Database:</strong> MySQL</li>
                                <li><strong>CMS:</strong> WordPress, Bitrix24</li>
                                <li><strong>IDE/Tools:</strong> Visual Studio Code, JetBrains</li>

                            </ul>
                        </div>

                        {/* Work Experience Section */}
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold text-red-600">Work Experience</h3>
                            <ul className="mt-4 space-y-2 text-white">
                                <li><strong>Senior Developer</strong> at Boscosoft Technologies Pvt. Ltd. (Apr 2024 -
                                    Present)
                                </li>
                                <li><strong>Junior Developer</strong> at Boscosoft Technologies Pvt. Ltd. (Apr 2022 - Mar 2024)</li>
                                <li><strong>Trainee</strong> at Boscosoft Technologies Pvt. Ltd. (Mar 2021 - Mar 2022)</li>
                            </ul>
                        </div>

                        {/* Education Section */}
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold text-red-600">Education</h3>
                            <p className="mt-4 text-white">
                                Bachelor of Computer Science, Don Bosco College, Dharmapuri (Graduated in 2019)
                            </p>
                        </div>

                        {/* Awards Section */}
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold text-red-600">Awards & Recognition</h3>
                            <ul className="mt-4 space-y-2 text-white">
                                <li>Star Performer - Boscosoft Technologies (Apr - Jul 2022)</li>
                                <li>Star Performer - Boscosoft Technologies (Aug - Nov 2022)</li>
                                <li>Extra Miler Award - Boscosoft Technologies (Apr - Jul 2024)</li>
                            </ul>
                        </div>

                        {/* Contact Section */}
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold text-red-600">Contact</h3>
                            <ul className="mt-4 space-y-2 text-white">
                                <li><strong>Email:</strong> <a href="mailto:elangosurya85@gmail.com" className="text-blue-400">elangosurya85@gmail.com</a></li>
                                <li><strong>Phone:</strong> +91 95783 56235</li>
                                <li><strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/elango-mariyappan/" className="text-blue-400">linkedin.com/in/elango-mariyappan</a></li>
                                <li><strong>GitHub:</strong> <a href="https://github.com/Elangosurya1998" className="text-blue-400">github.com/Elangosurya1998</a></li>
                            </ul>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default About;
