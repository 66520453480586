import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes,useLocation } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import Work from "./pages/Work";
import Resume from "./pages/Resume";
import Contact from "./pages/Contact";
import ProjectDetail from "./pages/ProjectDetail";

const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return null;
};



function App() {
    return (
        <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
            <ScrollToTop />
            <div className="flex flex-col min-h-screen relative bg-[#1a1b1f]">
                <main className="flex-grow relative z-10">
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/projects" element={<Work/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/resume" element={<Resume/>}/>
                        <Route path="/projects/:id" element={<ProjectDetail/>}/>
                    </Routes>
                    <Footer/>
                </main>
            </div>
        </Router>
    );
}

export default App;