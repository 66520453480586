import React from 'react';

function Contact() {
    // const [formData, setFormData] = useState({
    //     name: '',
    //     email: '',
    //     message: '',
    // });
    // const [isSubmitted, setIsSubmitted] = useState(false);
    //
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [name]: value });
    // };
    //
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Send form data (You can integrate an API or backend to handle this submission)
    //     setIsSubmitted(true);
    // };

    return (
        <div className="my-48">
            <div className="mt-16">
                <main className="py-6 px-4 sm:p-6 md:py-10 md:px-8">
                    <div className="max-w-4xl mx-auto grid lg:max-w-5xl lg:gap-x-20">
                        <h1 className="text-4xl font-bold text-white">Contact <span className="text-red-600">Me</span></h1>

                        {/* Contact Info Section */}
                        <div className="mt-8 text-white">
                            <h2 className="text-2xl font-semibold">Get in Touch</h2>
                            <p className="mt-4">
                                Feel free to reach out to me via the contact form or through my details below:
                            </p>

                            <div className="mt-8">
                                <h3 className="text-xl font-semibold text-red-600">Contact Information</h3>
                                <ul className="mt-4 space-y-2">
                                    <li><strong>Email:</strong> <a href="mailto:elangosurya85@gmail.com" className="text-blue-400">elangosurya85@gmail.com</a></li>
                                    <li><strong>Phone:</strong> +91 95783 56235</li>
                                    <li><strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/elango-mariyappan/" className="text-blue-400">linkedin.com/in/elango-mariyappan</a></li>
                                    <li><strong>GitHub:</strong> <a href="https://github.com/Elangosurya1998" className="text-blue-400">github.com/Elangosurya1998</a></li>
                                </ul>
                            </div>
                        </div>

                        {/* Contact Form Section */}
                        {/*<div className="mt-8 text-white">*/}
                        {/*    <h3 className="text-xl font-semibold text-red-600">Send a Message</h3>*/}
                        {/*    {isSubmitted ? (*/}
                        {/*        <div className="mt-4 text-green-500">*/}
                        {/*            <p>Your message has been sent successfully!</p>*/}
                        {/*        </div>*/}
                        {/*    ) : (*/}
                        {/*        <form onSubmit={handleSubmit} className="mt-4 space-y-4">*/}
                        {/*            <div>*/}
                        {/*                <label htmlFor="name" className="block text-sm font-medium">Name</label>*/}
                        {/*                <input*/}
                        {/*                    type="text"*/}
                        {/*                    id="name"*/}
                        {/*                    name="name"*/}
                        {/*                    value={formData.name}*/}
                        {/*                    onChange={handleChange}*/}
                        {/*                    className="mt-2 w-full px-4 py-2 bg-gray-800 text-white border border-gray-600 rounded-md"*/}
                        {/*                    required*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*            <div>*/}
                        {/*                <label htmlFor="email" className="block text-sm font-medium">Email</label>*/}
                        {/*                <input*/}
                        {/*                    type="email"*/}
                        {/*                    id="email"*/}
                        {/*                    name="email"*/}
                        {/*                    value={formData.email}*/}
                        {/*                    onChange={handleChange}*/}
                        {/*                    className="mt-2 w-full px-4 py-2 bg-gray-800 text-white border border-gray-600 rounded-md"*/}
                        {/*                    required*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*            <div>*/}
                        {/*                <label htmlFor="message" className="block text-sm font-medium">Message</label>*/}
                        {/*                <textarea*/}
                        {/*                    id="message"*/}
                        {/*                    name="message"*/}
                        {/*                    value={formData.message}*/}
                        {/*                    onChange={handleChange}*/}
                        {/*                    rows="4"*/}
                        {/*                    className="mt-2 w-full px-4 py-2 bg-gray-800 text-white border border-gray-600 rounded-md"*/}
                        {/*                    required*/}
                        {/*                ></textarea>*/}
                        {/*            </div>*/}
                        {/*            <button*/}
                        {/*                type="submit"*/}
                        {/*                className="mt-4 px-6 py-3 bg-red-600 text-white rounded-md"*/}
                        {/*            >*/}
                        {/*                Send Message*/}
                        {/*            </button>*/}
                        {/*        </form>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Contact;
