const ElangoLogo = () => {
    return (
        <div className="h-36 mt-16 pt-3 sm:pt-0 w-auto">
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 195.76 307.76"
                className="w-full h-full bg-white"
            >
                {/* Direct styling through `fill` */}
                <polygon
                    fill="#231f20"
                    points="97.6 82.35 73.62 62.53 73.62 105.27 97.51 125.02 123.47 103.74 137.85 91.95 137.85 121.29 170.79 121.29 170.79 22.35 137.85 49.35 97.6 82.35"
                />
                <polygon
                    fill="#231f20"
                    points="24.97 22.3 24.97 234.93 57.65 234.93 57.65 49.32 24.97 22.3"
                />
                <polygon
                    fill="#231f20"
                    points="149.86 226.02 103.53 226.02 103.53 200.37 141.38 200.37 149.86 226.02"
                />
                <polygon
                    fill="#231f20"
                    points="169.5 285.46 72.62 285.46 72.62 138.93 121.08 138.93 132.34 172.99 103.71 172.99 103.71 251.41 158.25 251.41 169.5 285.46"
                />
                <rect
                    fill="#ed1c24"
                    x="24.97"
                    y="251.41"
                    width="32.68"
                    height="34.05"
                />
                <polygon
                    fill="#ed1c24"
                    points="169.79 138.93 169.79 172.99 133.34 172.99 122.08 138.93 169.79 138.93"
                />
                <polygon
                    fill="#ed1c24"
                    points="158.79 200.37 158.79 226.02 150.86 226.02 142.38 200.37 158.79 200.37"
                />
                <polygon
                    fill="#ed1c24"
                    points="170.79 251.41 170.79 285.46 170.5 285.46 159.25 251.41 170.79 251.41"
                />
            </svg>
        </div>
    );
};

export default ElangoLogo;